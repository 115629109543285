<template>
    <div class="markdown-body markdown-container">
        <terms></terms>
    </div>
</template>
<script>
import terms from '@/assets/md/terms.md';
export default {
    data() {
        return {};
    },
    components: {
        terms,
    },
};
</script>
<style scoped>
.markdown-container {
    max-width: 960px;
    margin: 0 auto;
    padding: 60px 30px;
    box-sizing: content-box;
    text-align: left;
}
</style>
